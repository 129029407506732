export default class Status {
    public value: any = {};

    constructor() { }

    public bind(name: string, data: any) {
        this.value[name] = data;
    }

    public unbind(name: string) {
        delete this.value[name];
    }

    public get(name: string) {
        return this.value[name];
    }
}