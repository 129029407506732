import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.lpsys.community.board');
import { OnInit, Input } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-portal-lpsys-community-board',
template: templateSource || '',
    styles: [`

/* file: /opt/lpsys-dev/project/main/build/src/app/portal.lpsys.community.board/view.scss */
/* src/app/portal.lpsys.community.board/view.scss: no such file or directory */`],
})
export class PortalLpsysCommunityBoardComponent implements OnInit {
    constructor(@Inject( Service) public service: Service) { }

    @Input() category: any;

    public categoryMap: any = { notice: '공지사항', news: '뉴스', experts: '전문가풀' };

    public items: any = [];
    public loaded: boolean = false;

    public search: any = { page: 1 };

    public pagenation: any = {
        end: -1,
        start: -1,
    };

    public async ngOnInit() {
        await this.service.init();
        await this.service.auth.allow(true, "/");
        this.search.category = this.category;
        await this.load();
    }

    public async load(page: number = 1) {
        this.search.page = page;
        this.loaded = false;
        this.items = [];
        await this.service.render();
        const { code, data } = await wiz.call("search", this.search);
        if (code != 200) return;
        let { rows, lastpage } = data;
        const startpage = Math.floor((page - 1) / 10) * 10 + 1;
        this.items = rows;
        this.pagenation.start = startpage;
        this.pagenation.end = lastpage;
        this.loaded = true;
        await this.service.render();
    }

    public async changeCategory(category) {
        this.category = category;
        this.search.category = this.category;
        await this.load(1);
    }

    public async link(item) {
        this.service.href(`/community/${this.category}/view/${item.id}`)
    }

}

export default PortalLpsysCommunityBoardComponent;