import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.lpsysadmin.fund.list.operation');
import { OnInit, Input } from '@angular/core';
import { Service } from "src/libs/portal/season/service";
import moment from 'moment';

@Component({
    selector: 'wiz-portal-lpsysadmin-fund-list-operation',
template: templateSource || '',
    styles: [`

/* file: /opt/lpsys-dev/project/main/build/src/app/portal.lpsysadmin.fund.list.operation/view.scss */
/* src/app/portal.lpsysadmin.fund.list.operation/view.scss: no such file or directory */`],
})
export class PortalLpsysadminFundListOperationComponent implements OnInit {
    @Input() view: any;

    constructor(@Inject( Service) public service: Service) { }

    public async ngOnInit() {
        await this.service.init();
        if (this.view == "admin") await this.service.auth.allow.membership('admin', "/landing");
        else await this.service.auth.allow(true, "/landing");
        await this.load();
    }

    public loading: boolean = false;
    public list: any = [];
    public search: any = {
        text: "",
        page: 1,
        dump: 10000000
    }

    public pagination: any = {
        end: -1,
        start: -1,
        current: 0
    }

    public async load(page: number = 1) {
        if (this.loading) return;
        this.loading = true;
        this.pagination.current = page;
        this.search.page = page;
        this.list = [];
        await this.service.render();

        const { code, data } = await wiz.call("search", this.search);

        if (code == 200) {
            let { rows, lastpage } = data;
            const startpage = Math.floor((page - 1) / 10) * 10 + 1;
            this.list = rows;
            this.pagination.start = startpage;
            this.pagination.end = lastpage;
        }

        this.loading = false;
        await this.service.render();
    }
    public iterItems(item: any) {
        if (item.investment.length == 0) {
            let row: any = JSON.parse(JSON.stringify(item));
            row.investment = {};
            row._rowspan = 1;
            return [row];
        }

        let rows: any = [];
        let sum = 0;
        for (let i = 0; i < item.investment.length; i++) {
            let row: any = JSON.parse(JSON.stringify(item));
            if (i == 0) row._rowspan = item.investment.length;
            row.investment = item.investment[i];
            sum += row.investment.investment_amount;
            rows.push(row);
        }

        rows[0].investment_total = sum;
        return rows;
    }

    public formatter: any = {
        date: (date) => {
            if (!date) return "-";
            let res: any = moment(date).format("YYYY-MM-DD");
            if (res == 'Invalid date') return "-";
            return res;
        },
        manager: (name) => {
            if (!name) return "-";
            return name;
        }
    }
}

export default PortalLpsysadminFundListOperationComponent;