import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.lpsysadmin.widget.fund.member');
import { OnInit, Input } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-portal-lpsysadmin-widget-fund-member',
template: templateSource || '',
    styles: [`

/* file: /opt/lpsys-dev/project/main/build/src/app/portal.lpsysadmin.widget.fund.member/view.scss */
table td,
table th {
  vertical-align: baseline;
}

.form-control {
  border-radius: 4px;
}

.btn-opt {
  margin: 4px;
  border-radius: 30px;
  height: auto;
  font: normal normal normal 16px Montserrat;
  padding: 12px 30px;
  border: 1px solid #72787F;
}
.btn-opt:hover {
  color: #FF5933;
  border: 1px solid #FF5933;
  background-color: #FFF2ED;
}
.btn-opt.active {
  background-color: #FF5933;
  color: #FFF;
  border: 1px solid #FF5933;
}`],
})
export class PortalLpsysadminWidgetFundMemberComponent implements OnInit {
    constructor(@Inject( Service) public service: Service) { }

    @Input() fund: any;

    public members: any = [];
    public selected: any = null;
    public summary: any = {};

    public target_user: any = null;

    public userEvent: any = {
        close: async () => {
            this.target_user = null;
            await this.service.render();
        }
    }

    public roleMap: any = {
        gp: 'GP',
        user: '조합원',
        staff: '담당자'
    }

    public async clickUser(user) {
        this.target_user = null;
        await this.service.render();
        this.target_user = user.id;
        await this.service.render();
    }

    public async ngOnInit() {
        await this.service.init();
        await this.load();
    }

    public async load() {
        const { data } = await wiz.call("list", { id: this.fund.id });
        for (let i = 0; i < data.length; i++) {
            if (!data[i].extra) data[i].extra = {};
            if (!data[i].extra.cert) data[i].extra.cert = [];
            if (!data[i].extra.income_doc) data[i].extra.income_doc = [];
        }
        this.members = data;
        let { result, budget } = this.sum();
        this.summary = { result, budget };
        await this.service.render();
    }

    public async update() {
        const { code } = await wiz.call("update", { id: this.fund.id, data: JSON.stringify(this.members) });
        if (code == 200)
            await this.service.alert.success("저장되었습니다.");
        else
            await this.service.alert.error("오류가 발생했습니다.");
        await this.service.render();
    }

    public async remove(item) {
        if (item.id) {
            let res = await this.alert(`조합원 '${item.user.name}'을 정말로 삭제하시겠습니까?`, '삭제', '취소');
            if (!res) return;

            const { code } = await wiz.call("remove", { id: this.fund.id, data: JSON.stringify(item) });

            if (code != 200) {
                await this.alert(`오류가 발생했습니다.`);
                return
            }
        }

        await this.load();
    }

    public sum() {
        let members = this.members;
        let result = 0;

        for (let i = 0; i < members.length; i++) {
            let member = members[i];
            if (member.status == '참여')
                result += member.applied_amount;
        }

        let budget = this.service.formatter.currency(result * this.fund.amount_per_share);

        return { result, budget };
    }

    public async alert(message: string, action: string = '확인', cancel: any = false, status: string = 'error') {
        return await this.service.alert.show({
            title: "",
            message: message,
            cancel: cancel,
            actionBtn: status,
            action: action,
            status: status
        });
    }

    public mode: string = 'view';

    public async toggle(status) {
        this.mode = status;
        await this.load();
        await this.service.render();
    }

    public isEditable() {
        if (this.fund.investment_status == '결성') return true;
        if (this.mode == 'edit') return true;
        return false;
    }

    public async upload(item, target) {
        let files = await this.service.file.select();
        if (files.length == 0) return;

        await this.service.loading.show();
        for (let i = 0; i < files.length; i++) {
            let fd = new FormData();
            fd.append('upload', files[i]);
            let fdurl = '/file/upload?mode=fund.member-' + item.fund_id + "-" + item.user.id;
            const { url } = await this.service.file.upload(fdurl, fd, async (percent: number, total: number, position: number) => { });
            item.extra[target].push({ name: files[i].name, url: url });
        }
        await this.service.loading.hide();

        await this.update();
        this.service.render();
    }

    public async clearValue(obj, key) {
        obj.remove(key)
        await this.service.render();
        await this.update();
    }

}

export default PortalLpsysadminWidgetFundMemberComponent;