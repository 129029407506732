import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.season.alert');
import { OnInit, Input } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-portal-season-alert',
template: templateSource || '',
    styles: [``],
})
export class PortalSeasonAlertComponent implements OnInit {
    @Input() model: any = null;

    constructor(@Inject( Service) public service: Service) {
        if (!this.model) this.model = service.alert;
    }

    public async ngOnInit() {
    }

    public btnColorClass() {
        if (this.model.opts.status == 'warning')
            return 'inline-flex w-full justify-center rounded-md bg-yellow-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-yellow-500 sm:ml-3 sm:w-auto';
        if (this.model.opts.status == 'success')
            return 'inline-flex w-full justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 sm:ml-3 sm:w-auto';
        return 'inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto';
    }
}

export default PortalSeasonAlertComponent;