import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('page.fund.item');
import { OnInit, DoCheck } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-page-fund-item',
template: templateSource || '',
    styles: [`

/* file: /opt/lpsys-dev/project/main/build/src/app/page.fund.item/view.scss */
/* src/app/page.fund.item/view.scss: no such file or directory */`],
})
export class PageFundItemComponent implements OnInit, DoCheck {
    constructor(@Inject( Service) public service: Service) { }

    public isLoading: boolean = true;
    public status: string = '';
    public id: string = '';
    public menu: string = '';
    public sub: string = '';
    public sub_id: string = '';

    public async ngOnInit() {
        await this.service.init();
        await this.service.auth.allow(true, "/login");

        this.status = WizRoute.segment.status;
        this.id = WizRoute.segment.id;
        this.menu = WizRoute.segment.menu;
        this.sub = WizRoute.segment.sub;
        this.sub_id = WizRoute.segment.sub_id;

        this.isLoading = false;

        await this.service.render();

        if (!WizRoute.segment.status)
            return this.service.href("/fund/formed");
        else if (WizRoute.segment.id && !WizRoute.segment.menu)
            return this.service.href(`/fund/${WizRoute.segment.status}/${WizRoute.segment.id}/info`);
    }

    public async ngDoCheck() {
        if (!WizRoute.segment.status)
            return this.service.href("/fund/formed");
        else if (WizRoute.segment.id && !WizRoute.segment.menu)
            return this.service.href(`/fund/${WizRoute.segment.status}/${WizRoute.segment.id}/info`);

        this.status = WizRoute.segment.status;
        this.id = WizRoute.segment.id;
        this.menu = WizRoute.segment.menu;
        this.sub = WizRoute.segment.sub;
        this.sub_id = WizRoute.segment.sub_id;
    }
}

export default PageFundItemComponent;