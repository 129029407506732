import { Inject } from '@angular/core';
import templateSource from './app.component.html';
              import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz');
import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { Service } from "src/libs/portal/season/service";
import moment from 'moment';

@Component({
    selector: 'app-root',
template: templateSource || '',
    styles: [``],
})
export class AppComponent implements OnInit {
    constructor(@Inject( Service)         public service: Service,@Inject( Router)         public router: Router,@Inject( ChangeDetectorRef    )         public ref: ChangeDetectorRef    ) { }

    public async ngOnInit() {
        await this.service.init(this);

        this.service.alert.error = async (message: string, cancel: any = false, action: string = '확인') => {
            return await this.service.alert.show({
                title: "",
                message: message,
                cancel: cancel,
                actionBtn: 'error',
                action: action,
                status: 'error'
            });
        }

        this.service.alert.success = async (message: string, cancel: any = false, action: string = '확인') => {
            return await this.service.alert.show({
                title: "",
                message: message,
                cancel: cancel,
                actionBtn: 'success',
                action: action,
                status: 'success'
            });
        }

        this.service.formatter = {
            date: (date, text) => {
                if (!text) text = "-";
                if (!date) return text;
                let res = moment(date).format("YYYY-MM-DD");
                if (res == 'Invalid date') return text;
                return res;
            },
            currency(number, isString = true) {
                if (!number) return "-";
                if (isString) {
                    let num = number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                    return num + "원";
                }

                const units = ['', '만', '억', '조', '경'];
                const parts = [];
                let remaining = number;

                for (let i = units.length - 1; i >= 0; i--) {
                    const unit = Math.pow(10000, i);
                    const part = Math.floor(remaining / unit);
                    remaining = remaining % unit;

                    if (part > 0) {
                        parts.push(part + units[i]);
                    }
                }

                if (remaining > 0) {
                    parts.push(remaining);
                }
                if (parts.length == 0) return '-';
                return parts.join(' ') + '원';
            }
        }
    }
}
