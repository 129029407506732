import '@angular/compiler';
import { LayoutSidebarComponent } from './layout.sidebar/layout.sidebar.component';
import { PortalLpsysMyprofileEditComponent } from './portal.lpsys.myprofile.edit/portal.lpsys.myprofile.edit.component';
import { PortalSeasonStatusbarComponent } from './portal.season.statusbar/portal.season.statusbar.component';
import { LayoutEmptyComponent } from './layout.empty/layout.empty.component';
import { PortalLpsysadminWidgetFundMemberComponent } from './portal.lpsysadmin.widget.fund.member/portal.lpsysadmin.widget.fund.member.component';
import { PortalLpsysadminWidgetCompanyProductComponent } from './portal.lpsysadmin.widget.company.product/portal.lpsysadmin.widget.company.product.component';
import { PortalLpsysMyprofilePasswordComponent } from './portal.lpsys.myprofile.password/portal.lpsys.myprofile.password.component';
import { PortalLpsysCompanyItemComponent } from './portal.lpsys.company.item/portal.lpsys.company.item.component';
import { PageMyprofileComponent } from './page.myprofile/page.myprofile.component';
import { PageCompanyItemComponent } from './page.company.item/page.company.item.component';
import { PortalSeasonLoadingComponent } from './portal.season.loading/portal.season.loading.component';
import { PortalSeasonUiDropdownComponent } from './portal.season.ui.dropdown/portal.season.ui.dropdown.component';
import { PortalLpsysWidgetBoardComponent } from './portal.lpsys.widget.board/portal.lpsys.widget.board.component';
import { PageFundItemComponent } from './page.fund.item/page.fund.item.component';
import { PortalLpsysFundItemComponent } from './portal.lpsys.fund.item/portal.lpsys.fund.item.component';
import { PortalLpsysadminFundListFormedComponent } from './portal.lpsysadmin.fund.list.formed/portal.lpsysadmin.fund.list.formed.component';
import { PortalLpsysWidgetFundItemInvestmentComponent } from './portal.lpsys.widget.fund.item.investment/portal.lpsys.widget.fund.item.investment.component';
import { ComponentNavSidebarComponent } from './component.nav.sidebar/component.nav.sidebar.component';
import { PortalLpsysadminWidgetFundInviteComponent } from './portal.lpsysadmin.widget.fund.invite/portal.lpsysadmin.widget.fund.invite.component';
import { PortalLpsysWidgetFundItemInfoComponent } from './portal.lpsys.widget.fund.item.info/portal.lpsys.widget.fund.item.info.component';
import { PortalLpsysadminCompanyListComponent } from './portal.lpsysadmin.company.list/portal.lpsysadmin.company.list.component';
import { PortalLpsysadminWidgetFundDocumentComponent } from './portal.lpsysadmin.widget.fund.document/portal.lpsysadmin.widget.fund.document.component';
import { PortalSeasonAlertComponent } from './portal.season.alert/portal.season.alert.component';
import { PortalLpsysadminWidgetCompanyLogComponent } from './portal.lpsysadmin.widget.company.log/portal.lpsysadmin.widget.company.log.component';
import { PortalLpsysadminBoardItemComponent } from './portal.lpsysadmin.board.item/portal.lpsysadmin.board.item.component';
import { PortalLpsysadminFundListOperationComponent } from './portal.lpsysadmin.fund.list.operation/portal.lpsysadmin.fund.list.operation.component';
import { PortalLpsysadminWidgetFundBoardComponent } from './portal.lpsysadmin.widget.fund.board/portal.lpsysadmin.widget.fund.board.component';
import { PortalLpsysFundListExitComponent } from './portal.lpsys.fund.list.exit/portal.lpsys.fund.list.exit.component';
import { PortalLpsysFundListOperatingComponent } from './portal.lpsys.fund.list.operating/portal.lpsys.fund.list.operating.component';
import { PortalSeasonLoadingHexaComponent } from './portal.season.loading.hexa/portal.season.loading.hexa.component';
import { PageJoinComponent } from './page.join/page.join.component';
import { PortalLpsysadminWidgetUserProfileComponent } from './portal.lpsysadmin.widget.user.profile/portal.lpsysadmin.widget.user.profile.component';
import { PortalLpsysWidgetFundItemDocsComponent } from './portal.lpsys.widget.fund.item.docs/portal.lpsys.widget.fund.item.docs.component';
import { PortalLpsysMyprofileInfoComponent } from './portal.lpsys.myprofile.info/portal.lpsys.myprofile.info.component';
import { PortalLpsysadminFundItemComponent } from './portal.lpsysadmin.fund.item/portal.lpsysadmin.fund.item.component';
import { PortalLpsysFundListFormedComponent } from './portal.lpsys.fund.list.formed/portal.lpsys.fund.list.formed.component';
import { PortalSeasonTabComponent } from './portal.season.tab/portal.season.tab.component';
import { PortalLpsysadminFundListExitComponent } from './portal.lpsysadmin.fund.list.exit/portal.lpsysadmin.fund.list.exit.component';
import { PortalLpsysWidgetCompanyViewerComponent } from './portal.lpsys.widget.company.viewer/portal.lpsys.widget.company.viewer.component';
import { PortalLpsysadminUserListComponent } from './portal.lpsysadmin.user.list/portal.lpsysadmin.user.list.component';
import { PortalSeasonPaginationComponent } from './portal.season.pagination/portal.season.pagination.component';
import { PortalLpsysWidgetPdfviewerComponent } from './portal.lpsys.widget.pdfviewer/portal.lpsys.widget.pdfviewer.component';
import { PortalLpsysadminWidgetFundMemberSellComponent } from './portal.lpsysadmin.widget.fund.member.sell/portal.lpsysadmin.widget.fund.member.sell.component';
import { PortalLpsysadminWidgetCompanyBoardComponent } from './portal.lpsysadmin.widget.company.board/portal.lpsysadmin.widget.company.board.component';
import { PageAdminComponent } from './page.admin/page.admin.component';
import { PortalLpsysadminWidgetCompanyEditorComponent } from './portal.lpsysadmin.widget.company.editor/portal.lpsysadmin.widget.company.editor.component';
import { PortalLpsysWidgetCompanyProductComponent } from './portal.lpsys.widget.company.product/portal.lpsys.widget.company.product.component';
import { PortalLpsysCommunityBoardComponent } from './portal.lpsys.community.board/portal.lpsys.community.board.component';
import { PortalLpsysadminBoardListComponent } from './portal.lpsysadmin.board.list/portal.lpsysadmin.board.list.component';
import { LayoutNavbarComponent } from './layout.navbar/layout.navbar.component';
import { PortalLpsysadminWidgetFundInfoComponent } from './portal.lpsysadmin.widget.fund.info/portal.lpsysadmin.widget.fund.info.component';
import { PortalSeasonViewerTreeComponent } from './portal.season.viewer.tree/portal.season.viewer.tree.component';
import { PageFindpwComponent } from './page.findpw/page.findpw.component';
import { PageLoginComponent } from './page.login/page.login.component';
import { PortalLpsysadminWidgetCompanyInfoComponent } from './portal.lpsysadmin.widget.company.info/portal.lpsysadmin.widget.company.info.component';
import { PageCommunityComponent } from './page.community/page.community.component';
import { PortalLpsysadminWidgetFundInvestmentComponent } from './portal.lpsysadmin.widget.fund.investment/portal.lpsysadmin.widget.fund.investment.component';
import { PortalLpsysadminCompanyItemComponent } from './portal.lpsysadmin.company.item/portal.lpsysadmin.company.item.component';
import { PortalLpsysCommunityViewComponent } from './portal.lpsys.community.view/portal.lpsys.community.view.component';
import { PortalSeasonLoadingSeasonComponent } from './portal.season.loading.season/portal.season.loading.season.component';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, COMPOSITION_BUFFER_MODE } from '@angular/forms';
import { SortablejsModule } from "src/libs/portal/season/ngx-sortablejs";

@NgModule({
    declarations: [
        AppComponent,
        LayoutSidebarComponent,
        PortalLpsysMyprofileEditComponent,
        PortalSeasonStatusbarComponent,
        LayoutEmptyComponent,
        PortalLpsysadminWidgetFundMemberComponent,
        PortalLpsysadminWidgetCompanyProductComponent,
        PortalLpsysMyprofilePasswordComponent,
        PortalLpsysCompanyItemComponent,
        PageMyprofileComponent,
        PageCompanyItemComponent,
        PortalSeasonLoadingComponent,
        PortalSeasonUiDropdownComponent,
        PortalLpsysWidgetBoardComponent,
        PageFundItemComponent,
        PortalLpsysFundItemComponent,
        PortalLpsysadminFundListFormedComponent,
        PortalLpsysWidgetFundItemInvestmentComponent,
        ComponentNavSidebarComponent,
        PortalLpsysadminWidgetFundInviteComponent,
        PortalLpsysWidgetFundItemInfoComponent,
        PortalLpsysadminCompanyListComponent,
        PortalLpsysadminWidgetFundDocumentComponent,
        PortalSeasonAlertComponent,
        PortalLpsysadminWidgetCompanyLogComponent,
        PortalLpsysadminBoardItemComponent,
        PortalLpsysadminFundListOperationComponent,
        PortalLpsysadminWidgetFundBoardComponent,
        PortalLpsysFundListExitComponent,
        PortalLpsysFundListOperatingComponent,
        PortalSeasonLoadingHexaComponent,
        PageJoinComponent,
        PortalLpsysadminWidgetUserProfileComponent,
        PortalLpsysWidgetFundItemDocsComponent,
        PortalLpsysMyprofileInfoComponent,
        PortalLpsysadminFundItemComponent,
        PortalLpsysFundListFormedComponent,
        PortalSeasonTabComponent,
        PortalLpsysadminFundListExitComponent,
        PortalLpsysWidgetCompanyViewerComponent,
        PortalLpsysadminUserListComponent,
        PortalSeasonPaginationComponent,
        PortalLpsysWidgetPdfviewerComponent,
        PortalLpsysadminWidgetFundMemberSellComponent,
        PortalLpsysadminWidgetCompanyBoardComponent,
        PageAdminComponent,
        PortalLpsysadminWidgetCompanyEditorComponent,
        PortalLpsysWidgetCompanyProductComponent,
        PortalLpsysCommunityBoardComponent,
        PortalLpsysadminBoardListComponent,
        LayoutNavbarComponent,
        PortalLpsysadminWidgetFundInfoComponent,
        PortalSeasonViewerTreeComponent,
        PageFindpwComponent,
        PageLoginComponent,
        PortalLpsysadminWidgetCompanyInfoComponent,
        PageCommunityComponent,
        PortalLpsysadminWidgetFundInvestmentComponent,
        PortalLpsysadminCompanyItemComponent,
        PortalLpsysCommunityViewComponent,
        PortalSeasonLoadingSeasonComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        FormsModule,
        SortablejsModule,
        
    ],
    providers: [{
        provide: COMPOSITION_BUFFER_MODE,
        useValue: false
    }],
    bootstrap: [AppComponent]
})
export class AppModule { }