import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.lpsysadmin.widget.company.editor');
import { OnInit, OnDestroy, Input } from '@angular/core';
import { Service } from "src/libs/portal/season/service";
import { ElementRef, ViewChild } from '@angular/core';
import ClassicEditor from "src/libs/portal/season/ckeditor/ckeditor";

@Component({
    selector: 'wiz-portal-lpsysadmin-widget-company-editor',
template: templateSource || '',
    styles: [`

/* file: /opt/lpsys-dev/project/main/build/src/app/portal.lpsysadmin.widget.company.editor/view.scss */
/* src/app/portal.lpsysadmin.widget.company.editor/view.scss: no such file or directory */`],
})
export class PortalLpsysadminWidgetCompanyEditorComponent implements OnInit, OnDestroy {
    @ViewChild('editor')
    public editorElement: ElementRef;
    public editor: any;

    constructor(@Inject( Service) public service: Service) { }

    @Input() company: any;
    @Input() target: any;

    public async ngOnInit() {
        await this.service.init();

        let element: any = this.editorElement.nativeElement;
        let toolbar = 'heading | bold italic strikethrough underline | blockQuote code | bulletedList numberedList | outdent indent | uploadImage insertTable link codeBlock horizontalLine'.split(' ');

        this.editor = await ClassicEditor.create(element, {
            toolbar: {
                items: toolbar,
                shouldNotGroupWhenFull: true
            },
            removePlugins: ["MediaEmbedToolbar"],
            table: ClassicEditor.defaultConfig.table,
            simpleUpload: {
                uploadUrl: '/file/upload'
            }
        });

        this.editor.data.set(this.company.extra[this.target] ? this.company.extra[this.target] : '');
    }

    public async ngOnDestroy() {
        this.company.extra[this.target] = this.editor.data.get();
    }

    public async update() {
        this.company.extra[this.target] = this.editor.data.get();
        let body = JSON.parse(JSON.stringify(this.company));
        const { code } = await wiz.call("update", { info: JSON.stringify(body) });
        if (code == 200) await this.alert("저장되었습니다", "success");
        else await this.alert("오류가 발생했습니다.", "error");
        await this.service.render();
    }

    public async alert(message: string, status: string = 'error', cancel: any = false, action: string = '확인') {
        return await this.service.alert.show({
            title: "",
            message: message,
            cancel: cancel,
            actionBtn: status,
            action: action,
            status: status
        });
    }
}

export default PortalLpsysadminWidgetCompanyEditorComponent;