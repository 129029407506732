import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('page.myprofile');
import { OnInit } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-page-myprofile',
template: templateSource || '',
    styles: [`

/* file: /opt/lpsys-dev/project/main/build/src/app/page.myprofile/view.scss */
/* src/app/page.myprofile/view.scss: no such file or directory */`],
})
export class PageMyprofileComponent implements OnInit {
    constructor(@Inject( Service) public service: Service) { }

    public view: string = null;

    public async ngOnInit() {
        await this.service.init();
        await this.changeView("info");
        await this.service.loading.hide();
    }

    public async changeView(view) {
        this.view = view;
        await this.service.render();
    }

    public activeClass(view) {
        if (this.view == view)
            return 'cursor-pointer group flex gap-x-3 rounded-md bg-gray-50 py-2 pl-2 pr-3 text-sm font-semibold leading-6 text-indigo-600';
        return 'cursor-pointer group flex gap-x-3 rounded-md py-2 pl-2 pr-3 text-sm font-semibold leading-6 text-gray-700 hover:bg-gray-50 hover:text-indigo-600';
    }
}

export default PageMyprofileComponent;