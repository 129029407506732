import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.lpsysadmin.widget.fund.investment');
import { OnInit, Input } from '@angular/core';
import { Service } from "src/libs/portal/season/service";
import moment from 'moment';

@Component({
    selector: 'wiz-portal-lpsysadmin-widget-fund-investment',
template: templateSource || '',
    styles: [`

/* file: /opt/lpsys-dev/project/main/build/src/app/portal.lpsysadmin.widget.fund.investment/view.scss */
.wiz-form {
  border: 1px solid #6C8DF6;
}

.form-control-flush {
  text-align: left !important;
}`],
})
export class PortalLpsysadminWidgetFundInvestmentComponent implements OnInit {
    constructor(@Inject( Service) public service: Service) { }

    @Input() fund: any;
    @Input() investments: any;
    @Input() event: any;

    public async ngOnInit() {
        await this.service.init();
        for (let i = 0; i < this.investments.length; i++)
            this.investments[i].investment_date = moment(this.investments[i].investment_date).format("YYYY-MM-DD");

        await this.service.render();
    }

    public async update(item) {
        const { code } = await wiz.call("update", { info: JSON.stringify(item) });

        if (code == 200) await this.alert("저장되었습니다", "success");
        else await this.alert("오류가 발생했습니다. 다시 저장해주세요.", "error");

        await this.service.render();
    }

    public async clearValue(obj, key) {
        obj[key] = null;
        await this.service.render();
    }

    public async upload(item, target) {
        let files = await this.service.file.select();
        if (files.length == 0) return;

        await this.service.loading.show();
        let fd = new FormData();
        fd.append('upload', files[0]);
        let fdurl = '/file/upload';
        const { url } = await this.service.file.upload(fdurl, fd, async (percent: number, total: number, position: number) => { });
        item[target] = url;

        await this.service.loading.hide();
        this.service.render();
    }

    public async update(company) {
        company.fund_id = this.fund.id;
        const { code, data } = await wiz.call("update", { info: JSON.stringify(company) });

        if (code == 200) {
            data.investment_date = moment(data.investment_date).format("YYYY-MM-DD");
            this.investments[this.investments.indexOf(company)] = data;
            await this.alert(`저장되었습니다.`, '확인', false, 'success');
        } else {
            await this.alert(`오류가 발생했습니다. 다시 저장해주세요.`);
        }

        this.service.render();
    }

    public async add() {
        this.investments.push({ company: {}, extra: {}, sell1_data: {}, sell2_data: {}, sell3_data: {}, sell4_data: {}, sell5_data: {} });
        this.service.render();
    }

    public async addFile(company) {
        if (!company.extra.attachment) company.extra.attachment = [];
        company.extra.attachment.push({});
        this.service.render();
    }

    public async remove(item) {
        if (item.id) {
            let res = await this.alert(`투자기업 '${item.company_name}'을 정말로 삭제하시겠습니까?`, '삭제', '취소');
            if (!res) return;

            const { code } = await wiz.call("remove", { info: JSON.stringify(item) });

            if (code != 200) {
                await this.alert(`오류가 발생했습니다.`);
                return
            }
        }

        this.investments.remove(item);
        this.service.render();
    }

    public async alert(message: string, action: string = '확인', cancel: any = false, status: string = 'error') {
        return await this.service.alert.show({
            title: "",
            message: message,
            cancel: cancel,
            actionBtn: status,
            action: action,
            status: status
        });
    }

    public findDemodayModal: any;
    public findDemodayEvent: any = {
        close: async () => {
            this.findDemodayModal = null;
            await this.service.render();
        },
        select: async (item) => {
            let selected: any = this.findDemodayModal.item;
            selected.registed_number = item.registed_number;
            this.investments[this.findDemodayModal.index].company = { ...this.investments[this.findDemodayModal.index].company, ...item };
            this.findDemodayModal = null;
            await this.service.render();
        }
    }

    public async unlinkDemoday(item) {
        item.registed_number = null;
        await this.service.render();
    }

    public async findDemoday(item, i) {
        this.findDemodayModal = { text: item.registed_number, item: item, index: i };
        await this.service.render();
    }
}

export default PortalLpsysadminWidgetFundInvestmentComponent;