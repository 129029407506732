import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.lpsysadmin.widget.fund.invite');
import { OnInit, Input } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-portal-lpsysadmin-widget-fund-invite',
template: templateSource || '',
    styles: [`

/* file: /opt/lpsys-dev/project/main/build/src/app/portal.lpsysadmin.widget.fund.invite/view.scss */
table td,
table th {
  vertical-align: baseline;
}

.form-control {
  border-radius: 4px;
}

.btn-opt {
  margin: 4px;
  border-radius: 30px;
  height: auto;
  font: normal normal normal 16px Montserrat;
  padding: 12px 30px;
  border: 1px solid #72787F;
}
.btn-opt:hover {
  color: #FF5933;
  border: 1px solid #FF5933;
  background-color: #FFF2ED;
}
.btn-opt.active {
  background-color: #FF5933;
  color: #FFF;
  border: 1px solid #FF5933;
}`],
})
export class PortalLpsysadminWidgetFundInviteComponent implements OnInit {
    constructor(@Inject( Service) public service: Service) { }

    @Input() fund: any;
    @Input() mode: any = "invite";

    public async ngOnInit() {
        await this.service.init();
        await this.service.auth.allow.membership("admin", "/");
        await this.load();
    }

    public listloaded: boolean = false;
    public list: any = [];
    public status: string = 'active';
    public user_status: string = '승인';
    public count: number = 0;

    public search: any = {
        page: 1,
        orderby: "name",
        text: "",
    };

    public pagination: any = {
        end: -1,
        start: -1,
    };

    public async alert(message: string, status: any = "error") {
        return await this.service.alert.show({
            title: '',
            message: message,
            cancel: false,
            actionBtn: status,
            action: "확인",
            status: status
        });
    }

    public async load(page: number = 1, status: any = null) {
        if (!status) status = this.status;
        this.listloaded = false;
        let { code, data } = await wiz.call("list", {
            id: this.fund.id, 
            page: page, 
            text: this.search.text, 
            orderby: this.search.orderby, 
            user_status: this.user_status, 
            status: status
        });
        if (code != 200) return;
        let { rows, lastpage, count } = data;
        const startpage = Math.floor((page - 1) / 10) * 10 + 1;
        this.search.page = page;
        this.list = rows;
        this.pagination.start = startpage;
        this.pagination.end = lastpage;
        this.listloaded = true;
        this.status = status;
        this.count = count;
        await this.service.render();
    }

    public async pageMove(page: number) {
        await this.load(page);
    }

    public async changeRole(item: any) {
        if (item.check) {
            if (item.check.status == '참여') return await this.alert("참여중인 조합원은 열람권한을 변경할 수 없습니다.");
            if (item.check.status == '대기') return await this.alert("대기중인 조합원은 열람권한을 변경할 수 없습니다.");
        }

        if (this.mode == 'invite') {
            item.check = !item.check;
        } else {
            item.check = true;
        }

        let user = {
            email: item.email,
            fund_id: this.fund.id,
            check: item.check,
            invite: this.mode == 'invite'
        };
        const { code } = await wiz.call("update", user);
        if (code != 200) return alert("error");
        await this.load(this.search.page);
    }

    public activeClass(target: string, status: string) {
        if (target == status) {
            return "group flex gap-x-3 mr-2 rounded-full bg-gray-600 px-3.5 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600";
        }
        return "group flex gap-x-3 rounded-md mr-2 py-2 pl-2 pr-3 text-sm font-semibold leading-6 text-gray-700 hover:bg-gray-50 hover:text-gray-600";
    }

}

export default PortalLpsysadminWidgetFundInviteComponent;