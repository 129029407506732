import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.lpsys.fund.list.formed');
import { OnInit } from "@angular/core";
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-portal-lpsys-fund-list-formed',
template: templateSource || '',
    styles: [`

/* file: /opt/lpsys-dev/project/main/build/src/app/portal.lpsys.fund.list.formed/view.scss */
/* src/app/portal.lpsys.fund.list.formed/view.scss: no such file or directory */`],
})
export class PortalLpsysFundListFormedComponent implements OnInit {

    constructor(@Inject( Service) public service: Service) { }

    public data: any = [];

    public async ngOnInit() {
        await this.service.init();
        await this.load();
    }

    public async load() {
        const { data } = await wiz.call("load");
        this.data = data;
        await this.service.render();
    }

    public iterItems(item: any) {
        if (item.investment.length == 0) {
            let row: any = JSON.parse(JSON.stringify(item));
            if (!row.userdata) row.userdata = {};
            row.investment = {};
            row._rowspan = 1;
            return [row];
        }

        let rows: any = [];
        for (let i = 0; i < item.investment.length; i++) {
            let row: any = JSON.parse(JSON.stringify(item));
            if (i == 0) row._rowspan = item.investment.length;
            row.investment = item.investment[i];
            if (!row.userdata) row.userdata = {};
            rows.push(row);
        }

        return rows;
    }

    public formatter: any = {
        date: (date) => {
            if (!date) return "-";
            return moment(date).format("YYYY-MM-DD");
        },
        status: (item) => {
            let map: any = { '참여': '참여', '대기': '신청완료(예비)' };
            try {
                if (map[item.userdata.status])
                    return map[item.userdata.status];
            } catch (e) {
            }
            return "미참여";
        }
    }

}

export default PortalLpsysFundListFormedComponent;