import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.lpsysadmin.company.list');
import { OnInit, Input } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-portal-lpsysadmin-company-list',
template: templateSource || '',
    styles: [`

/* file: /opt/lpsys-dev/project/main/build/src/app/portal.lpsysadmin.company.list/view.scss */
/* src/app/portal.lpsysadmin.company.list/view.scss: no such file or directory */`],
})
export class PortalLpsysadminCompanyListComponent implements OnInit {
    @Input() view: any;
    @Input() category: any = 'investment';

    constructor(@Inject( Service) public service: Service) { }

    public async ngOnInit() {
        await this.service.init();
        if (this.view == "admin") await this.service.auth.allow.membership('admin', "/landing");
        else await this.service.auth.allow(true, "/landing");
        await this.load();
    }

    public loading: boolean = false;
    public list: any = [];
    public search: any = {
        text: "",
        page: 1,
        dump: 100000
    }

    public pagination: any = {
        end: -1,
        start: -1,
        current: 0
    }

    public async load(page: number = 1) {
        if (this.loading) return;
        this.loading = true;
        this.pagination.current = page;
        this.search.page = page;
        this.search.status = this.category;
        this.list = [];
        await this.service.render();

        const { code, data } = await wiz.call("search", this.search);

        if (code == 200) {
            let { rows, lastpage } = data;
            const startpage = Math.floor((page - 1) / 10) * 10 + 1;
            this.list = rows;
            this.pagination.start = startpage;
            this.pagination.end = lastpage;
        }

        this.loading = false;
        await this.service.render();
    }

    public iterItems(item: any) {
        if (item.investment.length == 0) {
            let row: any = JSON.parse(JSON.stringify(item));
            row.investment = {};
            row._rowspan = 1;
            return [row];
        }

        let rows: any = [];
        for (let i = 0; i < item.investment.length; i++) {
            let row: any = JSON.parse(JSON.stringify(item));
            if (i == 0) row._rowspan = item.investment.length;
            row.investment = item.investment[i];
            rows.push(row);
        }

        return rows;
    }

    public async removeItem(item) {
        let res = await this.service.alert.error("기업 정보를 삭제하시겠습니까?", "취소", "삭제");
        if (!res) return;
        const { code } = await wiz.call("remove", item);
        if (code !== 200) return alert("조합 삭제 실패!");
        await this.load(this.pagination.current);
    }

    public statusMap(item) {
        if (item.investment_status == '운영') return 'operation';
        if (item.investment_status == '해산') return 'exit';
        return 'formed';
    }

    public baseLink(uri: string) {
        if (this.category == 'review') {
            return '/admin/review_company' + uri;
        }
        return '/admin/company' + uri;
    }
}

export default PortalLpsysadminCompanyListComponent;