import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.lpsysadmin.widget.company.info');
import { OnInit, Input } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-portal-lpsysadmin-widget-company-info',
template: templateSource || '',
    styles: [`

/* file: /opt/lpsys-dev/project/main/build/src/app/portal.lpsysadmin.widget.company.info/view.scss */
/* src/app/portal.lpsysadmin.widget.company.info/view.scss: no such file or directory */`],
})
export class PortalLpsysadminWidgetCompanyInfoComponent implements OnInit {
    constructor(@Inject( Service) public service: Service) { }

    @Input() company: any;

    public async ngOnInit() {
        await this.service.init();
    }

    public async update() {
        let body = JSON.parse(JSON.stringify(this.company));
        const { code, data } = await wiz.call("update", { info: JSON.stringify(body) });
        if (code == 200) await this.alert("저장되었습니다", "success");
        else if (code == 201) {
            if (this.company.status == 'investment')
                await this.service.href(`/admin/company/${data}/info`);
            else
                await this.service.href(`/admin/review_company/${data}/info`);
            location.reload();
            return;
        }
        else await this.alert("사업자 등록번호를 확인해주세요.", "error");
        await this.service.render();
    }

    public async clearValue(obj, key) {
        obj[key] = null;
        await this.service.render();
    }

    public async upload(item, target) {
        let files = await this.service.file.select();
        if (files.length == 0) return;

        let fd = new FormData();
        fd.append('upload', files[0]);
        let fdurl = '/file/upload';
        const { url } = await this.service.file.upload(fdurl, fd, async (percent: number, total: number, position: number) => { });
        item[target] = url;
        this.service.render();
    }

    public async alert(message: string, status: string = 'error', cancel: any = false, action: string = '확인') {
        return await this.service.alert.show({
            title: "",
            message: message,
            cancel: cancel,
            actionBtn: status,
            action: action,
            status: status
        });
    }

    public timestamp() {
        return new Date().getTime();
    }

    public activeClass(target: string, status: string) {
        if (target == status) {
            return "group flex gap-x-3 mr-2 rounded-full bg-gray-600 px-3.5 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600";
        }
        return "group flex gap-x-3 rounded-md mr-2 py-2 pl-2 pr-3 text-sm font-semibold leading-6 text-gray-700 hover:bg-gray-50 hover:text-indigo-600";
    }

}

export default PortalLpsysadminWidgetCompanyInfoComponent;