import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.lpsys.myprofile.password');
import { OnInit } from "@angular/core";
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-portal-lpsys-myprofile-password',
template: templateSource || '',
    styles: [`

/* file: /opt/lpsys-dev/project/main/build/src/app/portal.lpsys.myprofile.password/view.scss */
/* src/app/portal.lpsys.myprofile.password/view.scss: no such file or directory */`],
})
export class PortalLpsysMyprofilePasswordComponent implements OnInit {
    public data: any = null;
    public field: any = '';
    public sub_field: any = '';
    public passwd: any = {};

    constructor(@Inject( Service) public service: Service) { }

    async ngOnInit() {
        await this.service.init();
        await this.service.auth.allow(true, "/login");
        await this.load();
    }

    public async load() {
        const { data } = await wiz.call("load");
        this.data = data;
        if (!this.data.extra) this.data.extra = {};

        if (this.data.field) this.data.field = this.data.field.split(",");
        else this.data.field = [];

        if (this.data.sub_field) this.data.sub_field = this.data.sub_field.split(",");
        else this.data.sub_field = [];

        await this.service.render();
    }

    public async updatePassword() {
        if (this.passwd.change != this.passwd.repeat) {
            await this.alert("비밀번호가 일치하지 않습니다");
            return
        }

        if (this.passwd.change.length < 8) return await this.alert("8글자 이상의 비밀번호를 설정해주세요");
        if (this.passwd.change.search(/[a-z]/i) < 0) return await this.alert("비밀번호에 알파벳을 포함해주세요");
        if (this.passwd.change.search(/[0-9]/) < 0) return await this.alert("비밀번호에 숫자를 포함해주세요");

        await this.service.loading.show();
        let passwd: any = {
            current: this.service.auth.hash(this.passwd.current),
            change: this.service.auth.hash(this.passwd.change)
        }
        const { code } = await wiz.call("update_password", passwd);
        await this.service.loading.hide();
        if (code == 200) {
            await this.service.alert.show({ title: "마이페이지", message: "비밀번호가 변경되었습니다.", action: "확인", actionBtn: "success", cancel: false, status: "success" });
            this.passwd = {};
        } else {
            await this.alert("비밀번호를 확인해주세요");
        }
        await this.service.render();
    }

    public async alert(message) {
        await this.service.alert.show({ title: "마이페이지", message: message, action: "확인", actionBtn: "error", cancel: false, status: "error" });
    }

}

export default PortalLpsysMyprofilePasswordComponent;