import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.lpsysadmin.widget.fund.document');
import { OnInit, Input } from '@angular/core';
import { Service } from "src/libs/portal/season/service";
import moment from 'moment';

@Component({
    selector: 'wiz-portal-lpsysadmin-widget-fund-document',
template: templateSource || '',
    styles: [``],
})
export class PortalLpsysadminWidgetFundDocumentComponent implements OnInit {
    constructor(@Inject( Service) public service: Service) { }

    @Input() fund: any;

    public loaded: boolean = false;
    public stage: string = "결성";

    public sortableOptions: any = {
        animation: 0,
        handle: '.handle'
    };


    public async ngOnInit() {
        await this.service.init();

        if (!this.fund.extra.document) this.fund.extra.document = {};
        if (!this.fund.extra.document['결성']) this.fund.extra.document['결성'] = { name: '', items: [] };
        if (!this.fund.extra.document['운용']) this.fund.extra.document['운용'] = { name: '', items: [] };
        if (!this.fund.extra.document['해산']) this.fund.extra.document['해산'] = { name: '', items: [] };

        this.fund.extra.document = { ...this.fund.extra.document };
        for (let key in this.fund.extra.document) {
            if (!['결성', '운용', '해산'].includes(key)) {
                delete this.fund.extra.document[key];
            }
        }

        if (['결성', '운용'].includes(this.fund.investment_status)) {
            this.stage = this.fund.investment_status;
        } else {
            this.stage = '해산';
        }

        this.loaded = true;
        await this.service.render();
    }

    public async update() {
        const { code } = await wiz.call("update", { info: JSON.stringify({ id: this.fund.id, extra: this.fund.extra }) });
        if (code == 200) await this.alert("저장되었습니다", "success");
        else await this.alert("오류가 발생했습니다. 다시 저장해주세요.", "error");
        await this.service.render();
    }

    public async clearValue(obj, key) {
        obj[key] = null;
        await this.service.render();
    }

    public async upload(item, target) {
        let files = await this.service.file.select();
        if (files.length == 0) return;

        await this.service.loading.show();
        let fd = new FormData();
        fd.append('upload', files[0]);
        let fdurl = '/file/upload';
        const { url } = await this.service.file.upload(fdurl, fd, async (percent: number, total: number, position: number) => { });
        item[target] = url;
        await this.service.loading.hide();
        await this.service.render();
    }

    public async save(group) {
        if (!group.name || group.name.length < 3) return await this.service.alert.error("3글자 이상으로 서류 그룹 이름을 정해주세요.");
        delete group.isnew;
        await this.service.render();
    }

    public async add() {
        this.fund.extra.document.push({ isnew: true, name: '', items: [] });
        await this.service.render();
    }

    public async addItem(group) {
        group.items.push({});
        await this.service.render();
    }

    public async remove(rows, item) {
        let res = await this.alert("정말로 삭제하시겠습니까?", "error", "취소", "삭제");
        if (!res) return;
        rows.remove(item);
        await this.service.render();
    }

    public async alert(message: string, status: string = 'error', cancel: any = false, action: string = '확인') {
        return await this.service.alert.show({
            title: "",
            message: message,
            cancel: cancel,
            actionBtn: status,
            action: action,
            status: status
        });
    }

    public tab: string = '서류설정';

    public async switchTab(tab: string) {
        this.tab = tab;
        if (tab == '서류조회') {
            await this.load();
        }
        await this.service.render();
    }

    public activeTab(tab: string) {
        if (this.tab == tab) return 'cursor-pointer rounded-md bg-gray-200 px-3 py-2 text-sm font-medium text-gray-800 flex items-center';
        return 'cursor-pointer rounded-md px-3 py-2 text-sm font-medium text-gray-600 hover:text-gray-800 flex items-center';
    }

    public users: any = [];

    public async load() {
        this.loaded = false;
        const { data } = await wiz.call("status", { id: this.fund.id });
        this.users = data;

        for (let i = 0; i < this.users.length; i++) {
            if (!this.users[i].extra.document_check) {
                this.users[i].extra.document_check = {};
            }
        }

        this.loaded = true;
        await this.service.render();
    }

    public async updateMember(item, isAlert = true) {
        const { code } = await wiz.call("update_member", { id: this.fund.id, info: JSON.stringify(item) });
        if (isAlert) {
            if (code == 200) await this.alert("저장되었습니다", "success");
            else await this.alert("오류가 발생했습니다. 다시 저장해주세요.", "error");
        }
    }

    public async checkDocument(item, tag, value) {
        item.extra.document_check[tag] = value;
        await this.updateMember(item, false);
        await this.service.render();
    }

    public checkDocumentClass(item, tag, value) {
        let v = item.extra.document_check[tag];
        if (v === value)
            return 'cursor-pointer mr-2 text-indigo-600 hover:text-indigo-900';
        if (value === null && v === undefined)
            return 'cursor-pointer mr-2 text-indigo-600 hover:text-indigo-900';
        return 'cursor-pointer mr-2 text-gray-400 hover:text-indigo-600';
    }

    public async checkAccount(item) {
        let res = await this.alert(!item.joined_at ? "입금 확인 처리 하시겠습니까?" : "입금 확인을 취소하시겠습니까?", "success");
        if (!res) return;
        if (!item.joined_at) item.joined_at = moment().format('YYYY-MM-DD HH:mm:ss');
        else item.joined_at = null;
        await this.updateMember(item);
        await this.service.render();
    }
}

export default PortalLpsysadminWidgetFundDocumentComponent;